import {
  product_range_route,
  SITE_SCOPES,
  LANGUAGE_ABBREVATIONS,
  winAndHike_routes,
} from "../../constants"
import { getCookie } from "../../helper"

export const routes = {
  [LANGUAGE_ABBREVATIONS.english.combined]: {
    index: `/`,
    productRange: `/${
      product_range_route[LANGUAGE_ABBREVATIONS.english.combined].slug
    }`,
    events: `/events`,
    aboutUs: `/markenwelt`,
    career: `https://www.rivella-group.com/de/karriere/unsere-welt`,
    media: `https://www.rivella-group.com/de/unternehmen/unternehmensprofil`,
    contact: `https://www.rivella-group.com/de/extras/kontakt`,
    impressum: `/impressum`,
    unternehmen: `https://www.rivella-group.com/de/unternehmen/unternehmensprofil`,
    facebook: "https://www.facebook.com/Rivella/",
    instagram: "https://www.instagram.com/rivella_ch/",
  },
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    index: `/`,
    promotion: `/promotion`,
    promotionRefreshSampling: `/refresh-sampling`,
    promotionRefreshYourFriend: `/refreshafriend`,
    productRange: `/${
      product_range_route[LANGUAGE_ABBREVATIONS.german.combined].slug
    }`,
    events: `/erleben`,
    aboutUs: `/markenwelt`,
    career: `https://www.rivella-group.com/de/karriere/unsere-welt`,
    media: `https://www.rivella-group.com/de/unternehmen/unternehmensprofil`,
    contact: `https://www.rivella-group.com/de/extras/kontakt`,
    impressum: `https://www.rivella-group.com/de/extras/impressum`,
    unternehmen: `https://www.rivella-group.com/de/unternehmen/unternehmensprofil`,
    newsletter: `https://www.rivella-group.com/de/newsletter`,
    facebook: "https://www.facebook.com/Rivella/",
    instagram: "https://www.instagram.com/rivella_ch/",
    winterPromotion: "/winter-promotion",
    refreshCashback: `/refresh-cash-back`,
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    index: `/`,
    promotion: `/promotion`,
    promotionRefreshSampling: `/refresh-sampling`,
    promotionRefreshYourFriend: `/refreshafriend`,
    productRange: `/${
      product_range_route[LANGUAGE_ABBREVATIONS.french.combined].slug
    }`,
    events: `/experience`,
    aboutUs: `/la-marque-rivella`,
    career: `https://www.rivella-group.com/fr/carriere/notre-unvivers`,
    media: `https://www.rivella-group.com/fr/medias/communiques-de-presse/actualite`,
    contact: ` https://www.rivella-group.com/fr/contact/demande-generale`,
    impressum: `https://www.rivella-group.com/fr/extras/footer-navigation-links/imprit`,
    unternehmen: `https://www.rivella-group.com/fr/entreprise/profile-dentreprise`,
    newsletter: `https://www.rivella-group.com/fr/newsletter`,
    facebook: "https://www.facebook.com/Rivella/",
    instagram: "https://www.instagram.com/rivella_ch/",
    winterPromotion: "/promotion/promotion-on-pack",
    refreshCashback: `/refresh-cash-back`,
  },
  [LANGUAGE_ABBREVATIONS.italian.combined]: {
    index: `/`,
    promotion: `/promozione`,
    // promotionRefreshYourFriend: `/promozione/refreshafriend`,
    productRange: `/${
      product_range_route[LANGUAGE_ABBREVATIONS.italian.combined].slug
    }`,
    events: `/events`,
    aboutUs: `/il-marchio-rivella`,
    career: `https://www.rivella-group.com/it/Carriera/il-nostro-mondo`,
    media: `https://www.rivella-group.com/it/media/comunicati-stampa/attualita`,
    contact: `https://www.rivella-group.com/it/extras/footer-navigation-links/kontakt`,
    impressum: `https://www.rivella-group.com/it/extras/footer-navigation-links/colphon`,
    unternehmen: `https://www.rivella-group.com/it/societa/profilo-della-societa`,
    newsletter: `https://www.rivella-group.com/it/newsletter`,
    facebook: "https://www.facebook.com/Rivella/",
    instagram: "https://www.instagram.com/rivella_ch/",
    winterPromotion: "/winter-promotion",
  },
  [LANGUAGE_ABBREVATIONS.france.combined]: {
    index: `/`,
    promotion: `/promotion/promotion-on-pack`,
    productRange: `/${
      product_range_route[LANGUAGE_ABBREVATIONS.france.combined].slug
    }`,
    events: `/evenements`,
    aboutUs: `/la-marque-rivella`,
    career: `https://www.rivella-group.com/fr/carriere/notre-unvivers`,
    media: `https://www.rivella-group.com/fr/medias/communiques-de-presse/actualite`,
    contact: `https://www.rivella-group.com/fr/extras/footer-navigation-links/contact`,
    impressum: `https://www.rivella-group.com/fr/extras/footer-navigation-links/imprit`,
    unternehmen: `https://www.rivella-group.com/fr/entreprise/profile-dentreprise`,
    facebook: "https://www.facebook.com/rivellafrance",
    instagram: "https://www.instagram.com/rivellafrance/",
    winterPromotion: "/promotion/promotion-on-pack",
    europaParkPromotion: `/promotion-europa-park`,
  },
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: {
    index: `/`,
    productRange: `/${
      product_range_route[LANGUAGE_ABBREVATIONS.luxembourg.combined].slug
    }`,
    events: `/evenements`,
    aboutUs: `/la-marque-rivella`,
    career: `https://www.rivella-group.com/fr/carriere/notre-unvivers`,
    media: `https://www.rivella-group.com/fr/medias/communiques-de-presse/actualite`,
    contact: `https://www.rivella-group.com/fr/extras/footer-navigation-links/contact`,
    impressum: `https://www.rivella-group.com/it/extras/footer-navigation-links/colphon`,
    unternehmen: `https://www.rivella-group.com/fr/entreprise/profile-dentreprise`,
    facebook: "https://www.facebook.com/rivella.luxembourg",
    instagram: "https://www.instagram.com/rivellaluxembourg/",
    winterPromotion: "/winter-promotion",
  },
}

export const mainSiteHeader = {
  [LANGUAGE_ABBREVATIONS.english.combined]: {
    logo: {
      commonIdentifier: "mainIndex",
      uri: routes[LANGUAGE_ABBREVATIONS.english.combined].index,
    },
    leftLinks: [
      // {
      //   type: `internal-link-bold`,
      //   label: `Promotion`,
      //   commonIdentifier: "promotion",
      //   uri: routes[LANGUAGE_ABBREVATIONS.english.combined].promotion,
      // },
      {
        type: `internal-link-bold`,
        label: `Sortiment`,
        commonIdentifier: "range",
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].productRange,
      },
      {
        type: `internal-link-bold`,
        label: `Events`,
        commonIdentifier: "events",
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].events,
      },
      {
        type: `internal-link-bold`,
        label: `Markenwelt`,
        commonIdentifier: "aboutUs",
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].aboutUs,
      },
    ],
    rightLinks: [
      {
        type: `search`,
      },
      {
        type: `external-link`,
        label: `Karriere`,
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].career,
      },
      {
        type: `external-link`,
        label: `Unternehmen`,
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].media,
      },
      {
        type: `language`,
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    logo: {
      commonIdentifier: "mainIndex",
      uri: routes[LANGUAGE_ABBREVATIONS.german.combined].index,
    },
    leftLinks: [
      // {
      //   type: `internal-link-bold`,
      //   label: `Promotion`,
      //   commonIdentifier: "promotion",
      //   uri: routes[LANGUAGE_ABBREVATIONS.german.combined].refreshCashback,
      // },
      {
        type: `external-link-top`,
        label: `Mach’s farbig.`,
        commonIdentifier: "machsfarbig",
        uri: "https://inspiration.rivella.ch/de/machs-farbig/",
      },
      {
        type: `internal-link-bold`,
        label: `Sortiment`,
        commonIdentifier: "range",
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].productRange,
      },
      {
        type: `external-link-top`,
        label: `Inspiration`,
        commonIdentifier: "inspiration",
        uri: "https://inspiration.rivella.ch/de/",
      },
      {
        type: `internal-link-bold`,
        label: `Erleben`,
        commonIdentifier: "events",
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].events,
      },
      // {
      //   type: `internal-link-bold`,
      //   label: `Markenwelt`,
      //   commonIdentifier: "aboutUs",
      //   uri: routes[LANGUAGE_ABBREVATIONS.german.combined].aboutUs,
      // },
      {
        type: `external-link-top`,
        label: `App`,
        commonIdentifier: "app",
        uri: "https://rivella-win.ch/de",
      },
    ],
    rightLinks: [
      {
        type: `search`,
      },
      {
        type: `external-link`,
        label: `Karriere`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].career,
      },
      {
        type: `external-link`,
        label: `Unternehmen`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].media,
      },
      {
        type: `language`,
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    logo: {
      commonIdentifier: "mainIndex",
      uri: routes[LANGUAGE_ABBREVATIONS.french.combined].index,
    },
    leftLinks: [
      // {
      //   type: `internal-link-bold`,
      //   label: `Concours`,
      //   commonIdentifier: "promotion",
      //   uri: routes[LANGUAGE_ABBREVATIONS.french.combined].refreshCashback,
      // },
      {
        type: `external-link-top`,
        label: `Colore la vie.`,
        commonIdentifier: "machsfarbig",
        uri: "https://inspiration.rivella.ch/fr/colore-la-vie/",
      },
      {
        type: `internal-link-bold`,
        label: `Assortiment`,
        commonIdentifier: "range",
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].productRange,
      },
      {
        type: `external-link-top`,
        label: `Inspiration`,
        commonIdentifier: "inspiration",
        uri: "https://inspiration.rivella.ch/fr/",
      },
      {
        type: `internal-link-bold`,
        label: `Événements`,
        commonIdentifier: "events",
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].events,
      },
      // {
      //   type: `internal-link-bold`,
      //   label: `La Marque Rivella`,
      //   commonIdentifier: "aboutUs",
      //   uri: routes[LANGUAGE_ABBREVATIONS.french.combined].aboutUs,
      // },
      {
        type: `external-link-top`,
        label: `App`,
        commonIdentifier: "app",
        uri: "https://rivella-win.ch/fr",
      },
    ],
    rightLinks: [
      {
        type: `search`,
      },
      {
        type: `external-link`,
        label: `Emplois et Carrière`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].career,
      },
      {
        type: `external-link`,
        label: `Médias`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].media,
      },
      {
        type: `language`,
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.italian.combined]: {
    logo: {
      commonIdentifier: "mainIndex",
      uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].index,
    },
    leftLinks: [
      // {
      //   // type: `internal-link-bold`,
      //   type: `external-link-top`,
      //   label: `Promozione`,
      //   commonIdentifier: "promotion",
      //   uri: "https://rivella-win.ch/de",
      // },
      {
        type: `internal-link-bold`,
        label: `Assortimento`,
        commonIdentifier: "range",
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].productRange,
      },
      // {
      //   type: `internal-link-bold`,
      //   label: `Events`,
      //   commonIdentifier: 'events',
      //   uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].events,
      // },
      {
        type: `internal-link-bold`,
        label: `Il Marchio Rivella`,
        commonIdentifier: "aboutUs",
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].aboutUs,
      },
    ],
    rightLinks: [
      {
        type: `search`,
      },
      {
        type: `external-link`,
        label: `Jobs e Carriera`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].career,
      },
      {
        type: `external-link`,
        label: `Media`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].media,
      },
      {
        type: `language`,
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.france.combined]: {
    logo: {
      commonIdentifier: "mainIndex",
      uri: routes[LANGUAGE_ABBREVATIONS.france.combined].index,
    },
    leftLinks: [
      {
        type: `internal-link-bold`,
        label: `Promotion`,
        commonIdentifier: "promotion",
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].promotion,
      },
      {
        type: `internal-link-bold`,
        label: `Assortiment`,
        commonIdentifier: "range",
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].productRange,
      },
      // {
      //   type: `internal-link-bold`,
      //   label: `Events`,
      //   commonIdentifier: 'events',
      //   uri: routes[LANGUAGE_ABBREVATIONS.france.combined].events,
      // },
      {
        type: `internal-link-bold`,
        label: `La Marque Rivella`,
        commonIdentifier: "aboutUs",
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].aboutUs,
      },
      {
        type: `internal-link-bold`,
        label: `Jeu Europa-Park`,
        commonIdentifier: "europaParkPromotion",
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].europaParkPromotion,
      },
    ],
    rightLinks: [
      {
        type: `search`,
      },
      {
        type: `external-link`,
        label: `Emplois et Carrière`,
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].career,
      },
      {
        type: `external-link`,
        label: `Médias`,
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].media,
      },
      {
        type: `language`,
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: {
    logo: {
      commonIdentifier: "mainIndex",
      uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].index,
    },
    leftLinks: [
      // {
      //   type: `internal-link-bold`,
      //   label: `Promotion`,
      //   commonIdentifier: 'promotion',
      //   uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].promotion,
      // },
      {
        type: `internal-link-bold`,
        label: `Assortiment`,
        commonIdentifier: "range",
        uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].productRange,
      },
      // {
      //   type: `internal-link-bold`,
      //   label: `Events`,
      //   commonIdentifier: 'events',
      //   uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].events,
      // },
      {
        type: `internal-link-bold`,
        label: `La Marque Rivella`,
        commonIdentifier: "aboutUs",
        uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].aboutUs,
      },
    ],
    rightLinks: [
      {
        type: `search`,
      },
      {
        type: `external-link`,
        label: `Emplois Et Carrière`,
        uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].career,
      },
      {
        type: `external-link`,
        label: `Médias`,
        uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].media,
      },
      {
        type: `language`,
      },
    ],
  },
}

const isLoggedIn = getCookie("user_hash")
const loginCommonIdentifier = isLoggedIn ? "account" : "login"

export const winAndHikeHeader = {
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    logo: {
      commonIdentifier: "hikingIndex",
      uri: routes[LANGUAGE_ABBREVATIONS.german.combined].index,
    },
    leftLinks: [
      {
        type: `internal-link-bold`,
        label: `Gewinnspiel`,
        commonIdentifier: "contest",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.german.combined]
          .gewinnspiel,
      },
      {
        type: `internal-link-bold`,
        label: `Sofortpreis`,
        commonIdentifier: "instantPrice",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.german.combined]
          .sofortpreis,
      },
      {
        type: `internal-link-bold`,
        label: `Hauptpreise`,
        commonIdentifier: "grandPrice",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.german.combined]
          .hauptpreise,
      },
      {
        type: `internal-link-bold`,
        label: `Partnerregionen`,
        commonIdentifier: "partnerRegions",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.german.combined].overview,
      },
      {
        type: `internal-link-bold`,
        label: `Fragen`,
        commonIdentifier: "faq",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.german.combined].fragen,
      },
      {
        type: `internal-link-bold`,
        label: `Impressionen`,
        commonIdentifier: "impressions",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.german.combined]
          .impressionen,
      },
      {
        type: `hiking-login`,
        label: `Login`,
        accountLabel: `Account`,
        commonIdentifier: loginCommonIdentifier,
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.german.combined][
          loginCommonIdentifier
        ],
      },
    ],
    rightLinks: [
      {
        type: `language`,
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.italian.combined]: {
    logo: {
      commonIdentifier: "hikingIndex",
      uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].index,
    },
    leftLinks: [
      {
        type: `internal-link-bold`,
        label: `Concorso`,
        commonIdentifier: "contest",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.italian.combined]
          .gewinnspiel,
      },
      {
        type: `internal-link-bold`,
        label: `PREMIO IMMEDIATO`,
        commonIdentifier: "instantPrice",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.italian.combined]
          .sofortpreis,
      },
      {
        type: `internal-link-bold`,
        label: `PREMI PRINCIPALI`,
        commonIdentifier: "grandPrice",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.italian.combined]
          .hauptpreise,
      },
      {
        type: `internal-link-bold`,
        label: `Regione partner`,
        commonIdentifier: "partnerRegions",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.italian.combined].overview,
      },
      {
        type: `internal-link-bold`,
        label: `Domande`,
        commonIdentifier: "faq",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.italian.combined].fragen,
      },
      {
        type: `internal-link-bold`,
        label: `Impressioni`,
        commonIdentifier: "impressions",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.italian.combined]
          .impressionen,
      },
      {
        type: `hiking-login`,
        label: `Login`,
        accountLabel: `Account`,
        commonIdentifier: loginCommonIdentifier,
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.italian.combined][
          loginCommonIdentifier
        ],
      },
    ],
    rightLinks: [
      {
        type: `language`,
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    logo: {
      commonIdentifier: "hikingIndex",
      uri: routes[LANGUAGE_ABBREVATIONS.french.combined].index,
    },
    leftLinks: [
      {
        type: `internal-link-bold`,
        label: `Concours`,
        commonIdentifier: "contest",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.french.combined]
          .gewinnspiel,
      },
      {
        type: `internal-link-bold`,
        label: `PRIX IMMÉDIAT`,
        commonIdentifier: "instantPrice",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.french.combined]
          .sofortpreis,
      },
      {
        type: `internal-link-bold`,
        label: `PRIX PRINCIPAUX`,
        commonIdentifier: "grandPrice",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.french.combined]
          .hauptpreise,
      },
      {
        type: `internal-link-bold`,
        label: `Région partenaire`,
        commonIdentifier: "partnerRegions",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.french.combined].overview,
      },
      {
        type: `internal-link-bold`,
        label: `Questions`,
        commonIdentifier: "faq",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.french.combined].fragen,
      },
      {
        type: `internal-link-bold`,
        label: `Impressions`,
        commonIdentifier: "impressions",
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.french.combined]
          .impressionen,
      },
      {
        type: `hiking-login`,
        label: `Login`,
        accountLabel: `Account`,
        commonIdentifier: loginCommonIdentifier,
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.french.combined][
          loginCommonIdentifier
        ],
      },
    ],
    rightLinks: [
      {
        type: `language`,
      },
    ],
  },
}

export default {
  [SITE_SCOPES.main]: mainSiteHeader,
  [SITE_SCOPES.winAndHike]: winAndHikeHeader,
}
